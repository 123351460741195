<template>
  <div>
    <b-container class="mt-3">
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8" xl="7">
          <b-card title="Resend Verification Email" class="mt-3">
            <b-form class="m-2">
              <b-form-group id="input-group-vemail" label="If you didn't receive your account verification email we can re-send it to you:" label-for="input-vemail">
                <b-form-input id="input-vemail" v-model="email" type="email" required
                  placeholder="Enter email"></b-form-input>
              </b-form-group>
              <b-button @click="sendEmail" variant="primary">Send Email</b-button>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import usersAPI from '@/auth/users.js'

export default {
  data: function () {
    return {
      email: ""
    }
  },
  methods: {
    sendEmail() {
      usersAPI.resendVerificationEmail(this.email).then((response) => {
        console.log(response)
      }).catch(err => {
        console.log('got an error in onSubmit')
        console.dir(err.response.data.message)
        this.error = err.response.data.message
      })
    }
  }

}
</script>

<style></style>
