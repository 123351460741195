<template>
    <div>
        <b-container class="mt-3">
            <b-row align-h="center">
                <b-col cols="12" md="10" lg="8" xl="7">
                    <b-card title="Welcome to Safearth!" class="m-3">
                        To finish signing up for a SafearthID, 
                        you will need to verify your email address.
                        <p class="mt-3">
                            Please check your email for a message from <i>Safearth &lt;no-reply@safearth.com&gt;</i>
                            and click on the link in the email to verify your email address.
                        </p>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>
