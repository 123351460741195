<template>
  <div>
    <b-container class="mt-3">
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8" xl="7">
          <b-card title="Send Password Reset Email" class="mt-3">
            <b-form class="m-2">
              <b-form-group id="input-group-premail" label="Forgotten your password? We can send you a reset email:" label-for="input-premail">
                <b-form-input id="input-premail" v-model="email" type="email" required
                  placeholder="Enter email"></b-form-input>
              </b-form-group>
              <b-button @click="sendPasswordEmail" variant="primary">Send Email</b-button>
            </b-form>
            <b-alert :show="message != null" class="mt-4" variant="danger">
              {{ message }}
            </b-alert>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import usersAPI from '@/auth/users.js'

export default {
  data: function () {
    return {
      email: "",
      message: null,
    }
  },
  methods: {
    sendPasswordEmail() {
      this.message = null
      usersAPI.sendPasswordResetEmail(this.email).then((response) => {
        console.log(response)
        this.message = "A Reset Password email has been sent to the above address. Please check your inbox and follow the instructions in the email."
      }).catch(err => {
        console.log('got an error in onSubmit')
        console.dir(err.response.data.message)
        this.message = err.response.data.message
      })
    }
  }

}
</script>

<style></style>
