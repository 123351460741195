<template>
  <div>
    <b-container class="mt-3">
      <b-row align-h="center">
        <b-col cols="12" md="10" lg="8" xl="7">
          <b-card title="Sign up for a SafearthID" class="mt-3">
            <b-form class="m-2">
              <b-form-group id="input-group-email" label="Email:" label-for="input-email">
                <b-form-input id="input-email" v-model="email" type="email" required
                  placeholder="Your (corporate) email address"></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-name" label="Name:" label-for="input-name">
                <b-form-input id="input-name" v-model="name" required placeholder="Your Full Name"></b-form-input>
              </b-form-group>
              <hr />
              <b-form-group id="input-group-password" label="Password:" label-for="input-password">
                <b-form-input id="input-password" v-model="password" type="password" required
                  placeholder="Enter a password for your account"></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-password-confirm" label="Confirm Password:"
                label-for="input-confirm-password">
                <b-form-input id="input-confirm-password" v-model="confirm_password" type="password" required
                  placeholder="Confirm the password for your account"></b-form-input>
              </b-form-group>

              <b-card class="text-center mr-4 ml-4 mt-5" bg-variant="light">
                <p>
                  By using our services you are agreeing to Safearth’s
                  <b-link href="https://www.safearth.com/terms-of-service/" target="_blank">Terms of Service</b-link>
                  <br />
                  <span class="text-muted">(You should also review our
                    <b-link href="https://www.safearth.com/privacy-policy/" target="_blank"> Privacy Policy</b-link>
                    and <b-link href="https://www.safearth.com/fair-use-policy/" target="_blank"> Fair Use Policy</b-link>).</span>
                </p>
                <b-button @click="onSubmit" variant="primary" block class="w-50 p-2 mt-4 ml-auto mr-auto">Agree &amp; Sign-Up</b-button>
                <!-- <b-button type="reset" variant="danger">Reset</b-button> -->
                <b-container v-if="error" class="my-4">
                  <strong>Error:</strong> {{ error }}
                </b-container>
              </b-card>
            </b-form>
          </b-card>
        </b-col>
      </b-row>
    </b-container>


  </div>
</template>

<script>
import usersAPI from '@/auth/users.js'

export default {
  name: 'SignUp',
  data: function () {
    return {
      email: "",
      name: "",
      password: "",
      confirm_password: "",
      error: null,
      tosAccepted: false
    }
  },
  computed: {
    user: {
      get() {
        return {
          name: this.name,
          email: this.email,
          password: this.password
        }
      }
    },
  },
  methods: {
    onSubmit() {
      if (this.password !== this.confirm_password) {
        this.error = 'Your password do not match.';
        return;
      }
      else if (this.password.length < 8) {
        this.error = 'Your password is not long enough. Please use a longer password.';
        return;
      }

      this.error = null;
      usersAPI.addNewUser(this.user).then(() => {
        //console.log(response)
        this.$router.replace('/verify')
      }).catch(err => {
        console.log('got an error in onSubmit')
        console.dir(err.response.data.message)
        this.error = err.response.data.message
      })
    }
  }

}
</script>

<style></style>
